<template>
  <ActivityContentWrapper>
    <sdCards title="Delivery Notifications">
      <ul class="activity-list">
        <li v-for="item of notifications" v-bind:key="item.title" class="activity-list__single">
          <span class="activity-icon success" :class="getClass(item.shipmentState)">
            <sdFeatherIcons :type="getIcon(item.shipmentState)" size="14" />
          </span>
          <div class="activity-content">
            <div class="activity-info">
<!--              <img :src="require('@/static/img/chat-author/t1.jpg')" alt="" />-->
              <p>
                <span class="inline-text">{{item.title}}</span><br/>
                <span>{{ item.body }}</span>
                <span class="hour">{{ getDate(item.dateCreated) }}</span>
              </p>
            </div>
            <a class="activity-more" to="#">
              <sdDropdown :action="['click']">
                <template #overlay>
                  <a to="#">
                    <span>Hide</span>
                  </a>
                  <a to="#">
                    <span>Delete</span>
                  </a>
                </template>
                <sdFeatherIcons type="more-horizontal" />
              </sdDropdown>
            </a>
          </div>
        </li>
      </ul>
    </sdCards>
  </ActivityContentWrapper>
</template>
<script>
import { ActivityContentWrapper } from "./style";
import {computed, defineComponent, ref} from 'vue';
import {useRoute} from "vue-router";
import {DataService} from "@/config/dataService/dataService";
import moment from "moment";

const ActivityContent = defineComponent({
  name: 'ActivityContent',
  components: {ActivityContentWrapper},
  setup() {
    let dateCreated = computed((date) => moment(date).format("yyyy"));
    let notifications = ref([]);

    return {
      notifications,
      dateCreated
    }
  },
  methods: {
    getDate(date) {
      if (!date) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD HH:mm")
    },
    getIcon(status) {
      if (!status) {
        return "alert-circle";
      }
      if (status === "Received") {
        return "inbox"
      }
      if (status === "Delivered") {
        return "home"
      }
      if (status === "Processing") {
        return "edit"
      }
      if (status === "InTransit") {
        return "truck"
      }
      if (status === "Dispatched") {
        return "external-link"
      }
      if (status === "TransitWarning") {
        return "alert-circle"
      }
      if (status === "DeliveryWarning") {
        return "alert-circle"
      }
      return "alert-circle"
    },
    getClass(status) {
      if (!status) {
        return "warning";
      }
      if (status === "Received") {
        return "success"
      }
      if (status === "Delivered") {
        return "success"
      }
      if (status === "Processing") {
        return "info"
      }
      if (status === "InTransit") {
        return "info"
      }
      if (status === "Dispatched") {
        return "info"
      }
      if (status === "TransitWarning") {
        return "warning"
      }
      if (status === "DeliveryWarning") {
        return "warning"
      }
      return "warning"
    }
  },
  mounted() {
    const route = useRoute();
    let shipmentId = route.query.shipmentId;
    let token = route.query.token;

    DataService.get(`portal/notifications?guid=${shipmentId}&token=${token}`).then(result => {
      this.notifications = result.data;
    })
  }
});

export default ActivityContent;
</script>
